import axios from 'axios';

const updatePhoneNumber = async (phoneNum) => {
     const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/updateAdminPhoneNumber",{data:{
      phone : phoneNum,
     }
       
     })
     return result.data;
}

const resetPassword = async (email) => {
   const result = await axios.post("https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyDzJTSYxA2xp_ZSnTeQn9NOsxi8_XjecdA",{
      email : email,
      requestType : "PASSWORD_RESET"
   })
     return result.data;
}


const getAllUsers = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getAllUsers")
     return result.data;
}


const getLogs = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/getLogs")
     return result.data;
}



const createUser = async (body) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createUser",body)
     return result.data;

}
const adminDashboardData = async () => {
   const result = await axios.get("https://waproject-5dc59.uw.r.appspot.com/internal/adminDashboardData")
   return result.data;
}

const editUser = async (item) => {
   console.log(item);
   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/editUser",{
      claim : item.customClaims,
      uid : item.uid,
      name : item.name,
      email : item.email,
            appLevel: item.customClaims.appLevel,
            role: item.customClaims.roles,
            FirstName: item.FirstName,
            LastName: item.LastName,
            Address1: item.Address1,
            Address2: item.Address2,
            Address3: item.Address3,
            City: item.City,
            State: item.State,
            ZipCode: item.ZipCode,
            OfficeLawFirm: item.OfficeLawFirm,
            WSBA: item.WSBA,
   })
     return result.data;

}


const deleteOrg = async (id) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/deleteOrg/"+id,{
      name : name,
     })
     return result.data;

}

const getAllOrg = async () => {
   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/getOrgs")
     return result.data;
}
const createNewOrg = async (name) => {

   const result = await axios.post("https://waproject-5dc59.uw.r.appspot.com/internal/createOrg",{
      name : name,
     })
     return result.data;

}


export {deleteOrg,getAllOrg,createNewOrg,updatePhoneNumber, resetPassword, getAllUsers, editUser,createUser, getLogs, adminDashboardData}